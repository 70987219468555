import React from "react";

export default class Typewriter extends React.Component {
  state = {
    typeColor: {
      color: "blue",
    },
    typed: "",
    shouldRefresh: false,
    inputStrings: this.props.inputStrings,
    currentUsedLanguage: this.props.currentUsedLanguage,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentUsedLanguage !== prevProps.currentUsedLanguage) {
      this.setState({ currentUsedLanguage: this.props.currentUsedLanguage });
      this.setState({ shouldRefresh: this.props.shouldRefresh });
      this.setState({ typed: "" });
      this.setState((state) => (state.inputStrings = this.props.inputStrings));
      // this.setState({ shouldRefresh: true });
      this._isMounted = true;
      const time = setTimeout(
        () =>
          this.typeWord(
            this.props.inputStrings[0],
            [].concat(this.props.inputStrings)
          ),
        1000
      );
      if (this.state.shouldRefresh) {
        this.setState({
          shouldRefresh: false,
        });
        clearTimeout(time);
        // this._isMounted = false;
      }
    }
  }

  componentDidMount() {
    let time = null;
    if (!this.state.shouldRefresh) {
      this._isMounted = true;
      time = setTimeout(
        () =>
          this.typeWord(
            this.props.inputStrings[0],
            [].concat(this.props.inputStrings)
          ),
        1000
      );
    } else if (this.state.shouldRefresh) {
      this.setState({
        shouldRefresh: false,
        typed: "",
      });
      // this._isMounted = false;
      clearTimeout(time);
    }
  }

  typeWord(word, words) {
    if (this._isMounted) {
      this.stringChecker(word, words);
    }
  }

  typeWriter(string, words) {
    if (this._isMounted) {
      if (string.length === 0) {
        words = words.slice(1);
        words[0] && this.typeWord(words[0], words);
      } else {
        this.setState((state, props) => ({
          typed: state.typed.concat(string[0]),
        }));

        const time = setTimeout(
          () => this.typeWriter(string.slice(1), words),
          70
        );
        if (this.state.shouldRefresh) {
          clearTimeout(time);
          // this._isMounted = false;
          this.setState({
            shouldRefresh: false,
            typed: "",
          });
        }
      }
      if (
        this.state.typed.includes("product.") ||
        this.state.typed.includes("bulunabilirim.")
      ) {
        setTimeout(() => {
          this.setState({
            typed: "",
          });
          setTimeout(() => words[0] && this.typeWord(words[0], words), 100);
        }, 1500);
      }
    }
  }

  stringChecker(string, words) {
    if (this._isMounted) {
      if (string === "Johnson") {
        this.setState({ typeColor: { color: "blue" } });
      } else {
        this.setState({ typeColor: { color: "white" } });
      }
      this.typeWriter(string, words);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <div style={this.state.typeColor}>{this.state.typed}</div>;
  }
}
