import Publications from "./Publications";
import App from "./App";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useState } from "react";

const Container = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState(false);
  const [currentUsedLanguage, setCurrentUsedLanguage] = useState("EN");
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const myCallback = (props) => {
    setCurrentLanguage(props.currentLanguage);
    setCurrentUsedLanguage(props.currentUsedLanguage);
    setShouldRefresh(props.shouldRefresh);
  };
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <App
              callBackFromParent={myCallback}
              currentLanguage={currentLanguage}
              currentUsedLanguage={currentUsedLanguage}
              shouldRefresh={shouldRefresh}
            />
          )}
        />
        <Route
          path="/publications"
          exact
          render={() => (
            <Publications
              callBackFromParent={myCallback}
              currentLanguage={currentLanguage}
              currentUsedLanguage={currentUsedLanguage}
              shouldRefresh={shouldRefresh}
              setCurrentLanguage={setCurrentLanguage}
              setCurrentUsedLanguage={setCurrentUsedLanguage}
              setShouldRefresh={setShouldRefresh}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Container;
